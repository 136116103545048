<kpi4me-select-element class="form-field-no-padding"
                       [formControlElement]="formControl"
                       [data]="selectElementData"
                       (newElementClick)="onNewElement($event)"
                       (deleteElementClick)="onDeleteElement($event)"
                       (changeElementClick)="onChangeElement($event)"
                       (updateTable)="onUpdateTable()"
                       [createNewEnabled]="true"
                       [emptyOptionAdded]="true"
                       #tooltip="matTooltip"
                       (modelChange)="confirmChange()"
                       [matTooltip]="getErrorMessage()"
                       [matTooltipDisabled]="getErrorMessage() === ''"
                       [hideLabel]="true"
></kpi4me-select-element>
